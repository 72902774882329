@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

html, body {
  @apply m-0 p-0 w-full h-full box-border bg-softbeige;
  font-family: 'Fredoka', sans-serif;
  font-weight: 300;
}

p, ol, li {
  font-family: "Raleway", sans-serif;
  font-weight: bold;
}

*,
*::before,
*::after {
  @apply box-border;
}

/* Custom CSS for transition-width */
.transition-width {
  transition: width 0.5s ease-in-out;
}